import React, { useState } from "react";
import axios from "axios";
import { ENDPOINT_NODE } from "../../backend";

const Upload = () => {
  const [excelData, setExcelData] = useState(null);
  const [html,setHtml] =useState(null)
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    console.log(file);
    setExcelData(file);
  };
  const enviar = async () => {
    try {
      const EndPoint = ENDPOINT_NODE + "/upload";
      let formData = new FormData();

      formData.append("file", excelData);
      await axios({
        url: EndPoint,
        method: "POST",
        //   headers: {
        //     "Content-Type": "multipart/form-data",
        //     "Authorization": "Bearer " + token,
        //   },
        // Attaching the form data
        data: formData,
      }).then((response) => {
        setHtml(<a href="/formularioV3" className="text-white">Enviado, regresar al formulario</a>)
        // toast.success('Your image has been processed')
        // sumarCantidad(this.id_usuario,this.servicio);
        // this.guardarNombreImage( this.id_usuario ,this.servicio,currentFile.name,response.data.Jobid)
      });
    } catch (error) {
      console.log("error");
    }
  };

  return (
    <div className="App container fondo">
      <div style={{ height: "250px" }}></div>

      <div className="text-center">
        <h1 className="h1 text-white"> Actualizar registro</h1>
        <div className="custom-input-file mt-5">
          <input
            type="file"
            className="mt-5 input-file"
            onChange={handleFileChange}
          />
          Cargar archivo
        </div>
        {excelData && (<h3 className="text-white text-center h3 mt-3"> {excelData.name}</h3>)}
        <br></br>
        <button className="btn btn-primary mt-1" onClick={enviar}>
          Enviar
        </button>
        
        {html && (<div className="text-center text-white mt-2">{html}</div>)}
        
      </div>
    </div>
  );
};

export default Upload;
