import { useState , useEffect} from "react";
import axios from "axios";
import { ENDPOINT_CLASIFIYER } from "../../backend";
import Modal from 'react-modal';
// import uploadService from "../../services/upload.service";
import {Toaster, toast } from "react-hot-toast";
// import Alert from '../../components/error/ErrorAlert';
// import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
Modal.setAppElement('#root');
function DragArea({ select,puntoVenta,datosIp,muebles2,descripcion}) {
  const [ImageSelectedPrevious, setImageSelectedPrevious] = useState([]);
  const [archivos,setArchivos] = useState("");
  const [descri,setDescri] = useState(descripcion)
  const [myvalue,setmyValue] = useState('')
  const [prueba,setPrueba] = useState([])
  const [prueba1,setPrueba1] = useState([])
  const [prueba2,setPrueba2] = useState([])
  const [prueba3,setPrueba3] = useState([])
  const [prueba4,setPrueba4] = useState([])
  const [prueba5,setPrueba5] = useState([])
  const [prueba6,setPrueba6] = useState([])
  const [prueba7,setPrueba7] = useState([])
  const [prueba8,setPrueba8] = useState([])
  const [prueba9,setPrueba9] = useState([])
  const [base64, setBase64] = useState([])
  const [base641, setBase641] = useState([])
  const [base642, setBase642] = useState([])
  const [base643, setBase643] = useState([])
  const [base644, setBase644] = useState([])
  const [base645, setBase645] = useState([])
  const [base646, setBase646] = useState([])
  const [base647, setBase647] = useState([])
  const [base648, setBase648] = useState([])
  const [base649, setBase649] = useState([])
  const [activate,setActivate] = useState(false)
  const [progress,setProgress] = useState(0)
  const [token,setToken ] = useState('')  
  const [finish,setFinish] = useState(false)
  const total = 5;
  const [texto,setTexto] = useState("Enviar")
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [descriptionText,setDescriptiontext] = useState(descripcion)
  // const [muebles, setMuebles] =useState(muebles2)
  console.log("_________")
  console.log(descripcion)
  useEffect(() => {
    // getToken(107,1);
}, []);
  const UploadFiles = async ()=>{
    setTexto("Enviando...")
    try {
      const EndPoint = ENDPOINT_CLASIFIYER+"/api-send-images"
      let formData = new FormData();
          if (muebles2 ==1) {
            formData.append("image", prueba[0])
          } else if (muebles2 ==2){
            formData.append("image", prueba1[0])
            formData.append("image", prueba2[0])
          } else if (muebles2==3 ){
            formData.append("image", prueba3[0])
            formData.append("image", prueba4[0])
            formData.append("image", prueba5[0])
          }else if (muebles2==4 ){
            formData.append("image", prueba6[0])
            formData.append("image", prueba7[0])
            formData.append("image", prueba8[0])
            formData.append("image", prueba9[0])
          }
          // for (let i= 0 ; i<prueba.length; i++){
          //   formData.append("image", prueba[i]);
          // }
          
          formData.append("username", "lavirginia@cognitive.la")
          formData.append("punto_venta", puntoVenta)
          formData.append("repositor", select)
          formData.append("categoria", "Especias")
          formData.append("ip_user", datosIp.ip_user)
          formData.append("user_country", datosIp.user_country)
          formData.append("conection_type", datosIp.conection_type)
          formData.append("latitude", datosIp.latitude)
          formData.append("longitude", datosIp.longitude)
          formData.append("current_time", datosIp.current_time)
          await axios({
            url: EndPoint,
            method: "POST",
            headers: {
              "Content-Type": "multipart/form-data",
              "Authorization": "Bearer " + token,
            },
            // Attaching the form data
            data: formData,
          })
          .then((response) => {
            // toast.success('Your image has been processed')
            setShowModal(true);
            setPrueba([])
            setBase64([])
            // sumarCantidad(this.id_usuario,this.servicio);
            
            // this.guardarNombreImage( this.id_usuario ,this.servicio,currentFile.name,response.data.Jobid)
          })
        } catch (error) {
          setShowAlert(true);
         
          if (error.response) {
            console.log(error.response.data); // Información detallada sobre el error de respuesta
            console.log(error.response.status); // Código de estado HTTP de la respuesta
            setMessageAlert("Error al mostrar");
          } else if (error.request) {
            console.log(error.request); // Información sobre la solicitud sin respuesta
            setMessageAlert("Error de solicitud");
          } else {
            console.log(error.message); // Otros errores
            setMessageAlert("Error desconocido");
          }
        }
  }
//   const getToken= async(id_user,service)=>{
//     try {
//       const response = await axios.post('ENDPOINT_NODE+/get-password-token', {
//           id_user: id_user,
//           id_service: service
//       });
//   } catch (error) {
      
//       if (error.response) {
//         // setMsg(error.response.data.msg);
//       }
//   }
// }
  
  const tenerToken = () => {
    
    let formData = new FormData();
    formData.append('username', "cristian@santabros.com.ar");
    formData.append('password', "carlos");
    axios({
        // Endpoint to send files
        url: ENDPOINT_CLASIFIYER+"/get-token",
        method: "POST",
        // Attaching the form data
        data: formData,
      })
      .then((response) => {
        setToken(response.data.access_token);
      });
    }
    const changeImage = (e) => {   
      añadirImagen(e.target.files)
      tenerToken()
      for (let i = 0; i<e.target.files.length; i++){     
        if (e.target.files[i] !== undefined) {
          const reader = new FileReader();        
          reader.readAsDataURL(e.target.files[i]);
          reader.onload = (e) => {
            e.preventDefault();
            if (base64.length == 1) {
              setBase64([e.target.result])
            } else if (base64.length != 1){
              setBase64(base64=>base64.concat(e.target.result))
            }
            
            
          };
        }
      }
    };
    const changeImage1 = (e) => {   
      añadirImagen1(e.target.files)
      tenerToken()
      for (let i = 0; i<e.target.files.length; i++){     
        if (e.target.files[i] !== undefined) {
          const reader = new FileReader();        
          reader.readAsDataURL(e.target.files[i]);
          reader.onload = (e) => {
            e.preventDefault();
            if (base641.length == 1) {
              setBase641([e.target.result])
            } else if (base641.length != 1){
              setBase641(base641=>base641.concat(e.target.result))
            }
            
            
          };
        }
      }
    };
    const changeImage2 = (e) => {   
      añadirImagen2(e.target.files)
      tenerToken()
      for (let i = 0; i<e.target.files.length; i++){     
        if (e.target.files[i] !== undefined) {
          const reader = new FileReader();        
          reader.readAsDataURL(e.target.files[i]);
          reader.onload = (e) => {
            e.preventDefault();
            if (base642.length == 1) {
              setBase642([e.target.result])
            } else if (base642.length != 1){
              setBase642(base642=>base642.concat(e.target.result))
            }
            
            
          };
        }
      }
    };
    const changeImage3 = (e) => {   
      añadirImagen3(e.target.files)
      tenerToken()
      for (let i = 0; i<e.target.files.length; i++){     
        if (e.target.files[i] !== undefined) {
          const reader = new FileReader();        
          reader.readAsDataURL(e.target.files[i]);
          reader.onload = (e) => {
            e.preventDefault();
            if (base643.length == 1) {
              setBase643([e.target.result])
            } else if (base643.length != 1){
              setBase643(base643=>base643.concat(e.target.result))
            }
            
            
          };
        }
      }
    };
    const changeImage4 = (e) => {   
      añadirImagen4(e.target.files)
      tenerToken()
      for (let i = 0; i<e.target.files.length; i++){     
        if (e.target.files[i] !== undefined) {
          const reader = new FileReader();        
          reader.readAsDataURL(e.target.files[i]);
          reader.onload = (e) => {
            e.preventDefault();
            if (base644.length == 1) {
              setBase644([e.target.result])
            } else if (base644.length != 1){
              setBase644(base644=>base644.concat(e.target.result))
            }
            
          };
        }
      }
    };
    const changeImage5 = (e) => {   
      añadirImagen5(e.target.files)
      tenerToken()
      for (let i = 0; i<e.target.files.length; i++){     
        if (e.target.files[i] !== undefined) {
          const reader = new FileReader();        
          reader.readAsDataURL(e.target.files[i]);
          reader.onload = (e) => {
            e.preventDefault();
            if (base645.length == 1) {
              setBase645([e.target.result])
            } else if (base645.length != 1){
              setBase645(base645=>base645.concat(e.target.result))
            }
            
          };
        }
      }
    };
    const changeImage6 = (e) => {   
      añadirImagen6(e.target.files)
      tenerToken()
      for (let i = 0; i<e.target.files.length; i++){     
        if (e.target.files[i] !== undefined) {
          const reader = new FileReader();        
          reader.readAsDataURL(e.target.files[i]);
          reader.onload = (e) => {
            e.preventDefault();
            if (base646.length == 1) {
              setBase646([e.target.result])
            } else if (base646.length != 1){
              setBase646(base646=>base646.concat(e.target.result))
            }
            
          };
        }
      }
    };
    const changeImage7= (e) => {   
      añadirImagen7(e.target.files)
      tenerToken()
      for (let i = 0; i<e.target.files.length; i++){     
        if (e.target.files[i] !== undefined) {
          const reader = new FileReader();        
          reader.readAsDataURL(e.target.files[i]);
          reader.onload = (e) => {
            e.preventDefault();
            if (base647.length == 1) {
              setBase647([e.target.result])
            } else if (base647.length != 1){
              setBase647(base647=>base647.concat(e.target.result))
            }
            
          };
        }
      }
    };
    const changeImage8 = (e) => {   
      añadirImagen8(e.target.files)
      tenerToken()
      for (let i = 0; i<e.target.files.length; i++){     
        if (e.target.files[i] !== undefined) {
          const reader = new FileReader();        
          reader.readAsDataURL(e.target.files[i]);
          reader.onload = (e) => {
            e.preventDefault();
            if (base648.length == 1) {
              setBase648([e.target.result])
            } else if (base648.length != 1){
              setBase648(base648=>base648.concat(e.target.result))
            }
            
          };
        }
      }
    };
    const changeImage9 = (e) => {   
      añadirImagen9(e.target.files)
      tenerToken()
      for (let i = 0; i<e.target.files.length; i++){     
        if (e.target.files[i] !== undefined) {
          const reader = new FileReader();        
          reader.readAsDataURL(e.target.files[i]);
          reader.onload = (e) => {
            e.preventDefault();
            if (base649.length == 1) {
              setBase649([e.target.result])
            } else if (base649.length != 1){
              setBase649(base649=>base649.concat(e.target.result))
            }
            
          };
        }
      }
    };
  const añadirImagen = (value) => {
    
      Array.from(value).forEach(archivos=>{
        if (prueba.length==1){
          setPrueba([archivos])
        } else if (prueba.length!=1) {
          setPrueba(prueba=>prueba.concat(archivos))
        }
        
    })
    
  }
  const añadirImagen1 = (value) => {
    
    Array.from(value).forEach(archivos=>{
      if (prueba.length==1){
        setPrueba1([archivos])
      } else if (prueba.length!=1) {
        setPrueba1(prueba1=>prueba1.concat(archivos))
      }
      
  })
  
}
const añadirImagen2 = (value) => {
    
  Array.from(value).forEach(archivos=>{
    if (prueba.length==1){
      setPrueba2([archivos])
    } else if (prueba.length!=1) {
      setPrueba2(prueba2=>prueba2.concat(archivos))
    }
    
})

}
const añadirImagen3 = (value) => {
    
  Array.from(value).forEach(archivos=>{
    if (prueba.length==1){
      setPrueba3([archivos])
    } else if (prueba.length!=1) {
      setPrueba3(prueba3=>prueba3.concat(archivos))
    }
    
})
}
const añadirImagen4 = (value) => {
    
  Array.from(value).forEach(archivos=>{
    if (prueba.length==1){
      setPrueba4([archivos])
    } else if (prueba.length!=1) {
      setPrueba4(prueba4=>prueba4.concat(archivos))
    }
    
})

}
const añadirImagen5 = (value) => {
    
  Array.from(value).forEach(archivos=>{
    if (prueba.length==1){
      setPrueba5([archivos])
    } else if (prueba.length!=1) {
      setPrueba5(prueba5=>prueba5.concat(archivos))
    }
    
})

}
const añadirImagen6 = (value) => {
    
  Array.from(value).forEach(archivos=>{
    if (prueba.length==1){
      setPrueba6([archivos])
    } else if (prueba.length!=1) {
      setPrueba6(prueba6=>prueba6.concat(archivos))
    }
    
})
}
const añadirImagen7 = (value) => {
    
  Array.from(value).forEach(archivos=>{
    if (prueba.length==1){
      setPrueba7([archivos])
    } else if (prueba.length!=1) {
      setPrueba7(prueba7=>prueba7.concat(archivos))
    }
    
})

}

const añadirImagen8 = (value) => {
    
  Array.from(value).forEach(archivos=>{
    if (prueba.length==1){
      setPrueba8([archivos])
    } else if (prueba.length!=1) {
      setPrueba8(prueba8=>prueba8.concat(archivos))
    }
    
})

}
const añadirImagen9 = (value) => {
    
  Array.from(value).forEach(archivos=>{
    if (prueba.length==1){
      setPrueba9([archivos])
    } else if (prueba.length!=1) {
      setPrueba9(prueba9=>prueba9.concat(archivos))
    }
    
})

}
  const removerImage = (value) =>{
    
    const a = base64
    const b = a.splice(value,1)
    setBase64(a)
    setPrueba(prueba.filter((item) => item !== prueba[value]))
  }
  const removerImage1 = (value) =>{
    
    const a = base641
    const b = a.splice(value,1)
    setBase641(a)
    setPrueba1(prueba1.filter((item) => item !== prueba1[value]))
  }
  const removerImage2 = (value) =>{
    
    const a = base642
    const b = a.splice(value,1)
    setBase642(a)
    setPrueba2(prueba2.filter((item) => item !== prueba2[value]))
  }
  
  const removerImage3 = (value) =>{
    
    const a = base643
    const b = a.splice(value,1)
    setBase643(a)
    setPrueba3(prueba3.filter((item) => item !== prueba3[value]))
  }
  const removerImage4 = (value) =>{
    
    const a = base644
    const b = a.splice(value,1)
    setBase644(a)
    setPrueba4(prueba4.filter((item) => item !== prueba4[value]))
  }
  const removerImage5 = (value) =>{
    
    const a = base645
    const b = a.splice(value,1)
    setBase645(a)
    setPrueba5(prueba5.filter((item) => item !== prueba5[value]))
  }
  const removerImage6 = (value) =>{
    
    const a = base646
    const b = a.splice(value,1)
    setBase646(a)
    setPrueba6(prueba6.filter((item) => item !== prueba6[value]))
  }
  const removerImage7 = (value) =>{
    
    const a = base647
    const b = a.splice(value,1)
    setBase647(a)
    setPrueba7(prueba7.filter((item) => item !== prueba7[value]))
  }
  const removerImage8 = (value) =>{
    
    const a = base648
    const b = a.splice(value,1)
    setBase648(a)
    setPrueba8(prueba8.filter((item) => item !== prueba8[value]))
  }
  const removerImage9 = (value) =>{
    
    const a = base649
    const b = a.splice(value,1)
    setBase649(a)
    setPrueba9(prueba9.filter((item) => item !== prueba9[value]))
  }
  const cerrarModal = () => {
    setShowModal(false)
    window.location.reload()
  }
  return (
    <div className="container">
      {/* <StyleDragArea> */}
      {/* {showAlert && <Alert message={messageAlert} />} */}
       {muebles2==1 &&
       <>
       <div className="">
       {descripcion && <h6 className="h3 text-white " > {descripcion[0]}</h6>}
          </div>
          <div className="image-upload-wrap text-center">
        <input
            className="file-upload-input"
            type="file"
            accept=".png, .jpg, .jpeg"
            
            value={myvalue}
            onChange={(e) => {
              changeImage(e);
            }}
          />
        <div className="text-information mt-4 ">
            <h6 className="h6 fontSize ocultar">Seleccione aquí de la galeria de fotos la imágen del punto de venta</h6>
            <div className="text-center margenn">
            <FontAwesomeIcon className="text-warning tamanio " icon={faCloudUploadAlt} />

            </div>
            
          
            
            
          </div>
          
          
        </div>
        <div className="row mt-5">
        {base64.map((imagen, index) => (
            <div key={index} className="text-center col-12 ">
            <img 
              src={imagen}
              alt=""
              height="75px"
              width="112px"
              className="imagenredonda"
            />
            <div>
            <button onClick={(e)=>removerImage(index,e)} className="my-3 btn btn-danger custom-btn">Remover</button>
            </div>
          </div>
                    ))}

        </div>  
       </>
        
        
        }

        {muebles2==2 && 
          <>
       {/* <div className="row">
          <div className="col-6">

          </div>
          <div className="col-6">

          </div>
       </div> */}
        <div className="row">
          <div className="col-6">
          <div className="largo-predeterminado margenn ">
          {descripcion && descripcion.length == 1 && (
            <h6 className="h3 text-white " >{descripcion[0]}</h6>
          )}
          {descripcion && descripcion.length == 2 && (
            <h6 className="h3 text-white " >{descripcion[0]}</h6>
          )}
          
          </div>
          <div className="image-upload-wrap text-center">
        <input
            className="file-upload-input"
            type="file"
            accept=".png, .jpg, .jpeg"
            
            value={myvalue}
            onChange={(e) => {
              changeImage1(e);
            }}
          />
        <div className="text-information mt-4 ">
            <h6 className="h6 fontSize ocultar">Seleccione aquí de la galeria de fotos la imágen del punto de venta</h6>
            <div className="text-center  margenn">
            <FontAwesomeIcon className="text-warning tamanio " icon={faCloudUploadAlt} />

            </div>
          
            
          </div>
          
          
        </div>
        <div className="row mt-5">
        {base641.map((imagen, index) => (
            <div key={index} className="text-center col-12 ">
            <img 
              src={imagen}
              alt=""
              height="75px"
              width="112px"
              className="imagenredonda"
            />
            <div>
            <button onClick={(e)=>removerImage1(index,e)} className="my-3 btn btn-danger custom-btn">Remover</button>
            </div>
          </div>
                    ))}

        </div>  
          </div>
          <div className="col-6">
          <div className="largo-predeterminado margenn">
          {descripcion && descripcion.length == 1 && (
            <h6 className="h3 text-white " >{descripcion[0]}</h6>
          )}
          {descripcion && descripcion.length == 2 && (
            <h6 className="h3 text-white " >{descripcion[1]}</h6>
          )}
          
          </div>
          <div className="image-upload-wrap text-center">
        <input
            className="file-upload-input"
            type="file"
            accept=".png, .jpg, .jpeg"
            
            value={myvalue}
            onChange={(e) => {
              changeImage2(e);
            }}
          />
        <div className="text-information mt-4 ">
            <h6 className="h6 fontSize ocultar">Seleccione aquí de la galeria de fotos la imágen del punto de venta</h6>
            <div className="text-center  margenn">
            <FontAwesomeIcon className="text-warning tamanio" icon={faCloudUploadAlt} />

            </div>
            
        
            
            
          </div>
          
          
        </div>
        <div className="row mt-5">
        {base642.map((imagen, index) => (
            <div key={index} className="text-center col-12 ">
            <img 
              src={imagen}
              alt=""
              height="75px"
              width="112px"
              className="imagenredonda"
            />
            <div>
            <button onClick={(e)=>removerImage2(index,e)} className="my-3 btn btn-danger custom-btn">Remover</button>
            </div>
          </div>
                    ))}

        </div> 
          </div>
          
        </div>
        
        </>
        }

        {muebles2==3 &&

        <div className="row">
          <div className="col-lg-4 col-6">
          <div className="largo-predeterminado margenn">
          {descripcion && descripcion.length == 1 && (
            <h6 className="h3 text-white " >{descripcion[0]}</h6>
          )}
          {descripcion && descripcion.length == 3 && (
            <h6 className="h3 text-white " >{descripcion[0]}</h6>
          )}
          </div>
          <div className="image-upload-wrap text-center">
        <input
            className="file-upload-input"
            type="file"
            accept=".png, .jpg, .jpeg"
            
            value={myvalue}
            onChange={(e) => {
              changeImage3(e);
            }}
          />
        <div className="text-information mt-4 ">
            <h6 className="h6 fontSize ocultar">Seleccione aquí de la galeria de fotos la imágen del punto de venta</h6>
            <div className="text-center margenn">
            <FontAwesomeIcon className="text-warning tamanio " icon={faCloudUploadAlt} />

            </div>
            
          </div>
          
          
        </div>
        <div className="row mt-5">
        {base643.map((imagen, index) => (
            <div key={index} className="text-center col-12 ">
            <img 
              src={imagen}
              alt=""
              height="75px"
              width="112px"
              className="imagenredonda"
            />
            <div>
            <button onClick={(e)=>removerImage3(index,e)} className="my-3 btn btn-danger custom-btn">Remover</button>
            </div>
          </div>
                    ))}

        </div>
          </div>
          <div className="col-lg-4 col-6">
          <div className="largo-predeterminado margenn">
          {descripcion && descripcion.length == 1 && (
            <h6 className="h3 text-white " >{descripcion[0]}</h6>
          )}
          {descripcion && descripcion.length == 3 && (
            <h6 className="h3 text-white " >{descripcion[1]}</h6>
          )}
          </div>
          <div className="image-upload-wrap text-center">
        <input
            className="file-upload-input"
            type="file"
            accept=".png, .jpg, .jpeg"
            
            value={myvalue}
            onChange={(e) => {
              changeImage4(e);
            }}
          />
        <div className="text-information mt-4 ">
            <h6 className="h6 fontSize ocultar">Seleccione aquí de la galeria de fotos la imágen del punto de venta</h6>
            <div className="text-center margenn">
            <FontAwesomeIcon className="text-warning tamanio" icon={faCloudUploadAlt} />

            </div>
            
            
            
          </div>
          
          
        </div>
        <div className="row mt-5">
        {base644.map((imagen, index) => (
            <div key={index} className="text-center col-12 ">
            <img 
              src={imagen}
              alt=""
              height="75px"
              width="112px"
              className="imagenredonda"
            />
            <div>
            <button onClick={(e)=>removerImage4(index,e)} className="my-3 btn btn-danger custom-btn">Remover</button>
            </div>
          </div>
                    ))}

        </div>
          </div>
          <div className="col-lg-4 col-6">
          <div className="largo-predeterminado margenn">
          {descripcion && descripcion.length == 1 && (
            <h6 className="h3 text-white " >{descripcion[0]}</h6>
          )}
          {descripcion && descripcion.length == 3 && (
            <h6 className="h3 text-white " >{descripcion[2]}</h6>
          )}
          </div>
          <div className="image-upload-wrap text-center">
        <input
            className="file-upload-input"
            type="file"
            accept=".png, .jpg, .jpeg"
            
            value={myvalue}
            onChange={(e) => {
              changeImage5(e);
            }}
          />
        <div className="text-information mt-4 ">
            <h6 className="h6 fontSize ocultar">Seleccione aquí de la galeria de fotos la imágen del punto de venta</h6>
            <div className="text-center margenn">
            <FontAwesomeIcon className="text-warning tamanio" icon={faCloudUploadAlt} />

            </div>
            
            
            
          </div>
          
          
        </div>
        <div className="row mt-5">
        {base645.map((imagen, index) => (
            <div key={index} className="text-center col-12 ">
            <img 
              src={imagen}
              alt=""
              height="75px"
              width="112px"
              className="imagenredonda"
            />
            <div>
            <button onClick={(e)=>removerImage5(index,e)} className="my-3 btn btn-danger custom-btn">Remover</button>
            </div>
          </div>
                    ))}

        </div>
          </div>
        </div>
        
        
        }

        {muebles2==4 &&  
        <>
        <div className="row">
          <div className="col-6">
          <div className="largo-predeterminado margenn">
          {descripcion && descripcion.length == 1 && (
            <h6 className="h3 text-white " >{descripcion[0]}</h6>
          )}
          {descripcion && descripcion.length == 4 && (
            <h6 className="h3 text-white " >{descripcion[0]}</h6>
          )}
          </div>
          <div className="image-upload-wrap text-center">
        <input
            className="file-upload-input"
            type="file"
            accept=".png, .jpg, .jpeg"
            
            value={myvalue}
            onChange={(e) => {
              changeImage6(e);
            }}
          />
        <div className="text-information mt-4 ">
            <h6 className="h6 fontSize ocultar">Seleccione aquí de la galeria de fotos la imágen del punto de venta</h6>
            <div className="text-center margenn">
            <FontAwesomeIcon className="text-warning tamanio " icon={faCloudUploadAlt} />

            </div>
            
            
          </div>
          
          
        </div>
        <div className="row mt-5">
        {base646.map((imagen, index) => (
            <div key={index} className="text-center col-12 ">
            <img 
              src={imagen}
              alt=""
              height="75px"
              width="112px"
              className="imagenredonda"
            />
            <div>
            <button onClick={(e)=>removerImage6(index,e)} className="my-3 btn btn-danger custom-btn">Remover</button>
            </div>
          </div>
                    ))}

        </div>
          </div>
          <div className="col-6">
          <div className="largo-predeterminado margenn">
          {descripcion && descripcion.length == 1 && (
            <h6 className="h3 text-white " >{descripcion[0]}</h6>
          )}
          {descripcion && descripcion.length == 4 && (
            <h6 className="h3 text-white " >{descripcion[1]}</h6>
          )}
          </div>
          <div className="image-upload-wrap text-center">
        <input
            className="file-upload-input"
            type="file"
            accept=".png, .jpg, .jpeg"
            
            value={myvalue}
            onChange={(e) => {
              changeImage7(e);
            }}
          />
        <div className="text-information mt-4 ">
            <h6 className="h6 fontSize ocultar">Seleccione aquí de la galeria de fotos la imágen del punto de venta</h6>
            <div className="text-center margenn">
            <FontAwesomeIcon className="text-warning tamanio " icon={faCloudUploadAlt} />

            </div>
            
            
          </div>
          
          
        </div>
        <div className="row mt-5">
        {base647.map((imagen, index) => (
            <div key={index} className="text-center col-12 ">
            <img 
              src={imagen}
              alt=""
              height="75px"
              width="112px"
              className="imagenredonda"
            />
            <div>
            <button onClick={(e)=>removerImage7(index,e)} className="my-3 btn btn-danger custom-btn">Remover</button>
            </div>
          </div>
                    ))}

        </div>
          </div>
          
        </div>
        <div className="row mt-5">
          <div className="col-6">
          <div className="largo-predeterminado margenn">
          {descripcion && descripcion.length == 1 && (
            <h6 className="h3 text-white " >{descripcion[0]}</h6>
          )}
          {descripcion && descripcion.length == 4 && (
            <h6 className="h3 text-white " >{descripcion[2]}</h6>
          )}
          </div>
          <div className="image-upload-wrap text-center">
        <input
            className="file-upload-input"
            type="file"
            accept=".png, .jpg, .jpeg"
            
            value={myvalue}
            onChange={(e) => {
              changeImage8(e);
            }}
          />
        <div className="text-information mt-4 ">
            <h6 className="h6 fontSize ocultar">Seleccione aquí de la galeria de fotos la imágen del punto de venta</h6>
            <div className="text-center margenn">
            <FontAwesomeIcon className="text-warning tamanio " icon={faCloudUploadAlt} />

            </div>
            
            
            
          </div>
          
          
        </div>
        <div className="row mt-5">
        {base648.map((imagen, index) => (
            <div key={index} className="text-center col-12 ">
            <img 
              src={imagen}
              alt=""
              height="75px"
              width="112px"
              className="imagenredonda"
            />
            <div>
            <button onClick={(e)=>removerImage8(index,e)} className="my-3 btn btn-danger custom-btn">Remover</button>
            </div>
          </div>
                    ))}

        </div>
          </div>
          <div className="col-6">
          <div className="largo-predeterminado margenn">
          {descripcion && descripcion.length == 1 && (
            <h6 className="h3 text-white " >{descripcion[0]}</h6>
          )}
          {descripcion && descripcion.length == 4 && (
            <h6 className="h3 text-white " >{descripcion[3]}</h6>
          )}
          </div>
          <div className="image-upload-wrap text-center">
        <input
            className="file-upload-input"
            type="file"
            accept=".png, .jpg, .jpeg"
            
            value={myvalue}
            onChange={(e) => {
              changeImage9(e);
            }}
          />
        <div className="text-information mt-4 ">
            <h6 className="h6 fontSize ocultar">Seleccione aquí de la galeria de fotos la imágen del punto de venta</h6>
            <div className="text-center margenn">
            <FontAwesomeIcon className="text-warning tamanio " icon={faCloudUploadAlt} />

            </div>           
          </div>
          
          
        </div>
        <div className="row mt-5">
        {base649.map((imagen, index) => (
            <div key={index} className="text-center col-12 ">
            <img 
              src={imagen}
              alt=""
              height="75px"
              width="112px"
              className="imagenredonda"
            />
            <div>
            <button onClick={(e)=>removerImage9(index,e)} className="my-3 btn btn-danger custom-btn">Remover</button>
            </div>
          </div>
                    ))}

        </div>
          </div>
          </div>
        </>
        }
      
      <Modal
        isOpen={showModal}
        onRequestClose={() => cerrarModal()}
        contentLabel="Imagen Enviada"
        className="modal-custom text-center fondo text-white"    >
        <h2>¡Imágen Enviada!</h2>
        <p>Tu imágen ha sido enviada exitosamente.</p>
        <div class="cerrar-btn-container">
        <button className="btn btn-warning cerrar-btn my-auto text-white" onClick={() => cerrarModal()}>Cerrar</button>
        </div>
      </Modal>
        {/* <div className="row mt-5">
        {base64.map((imagen, index) => (
            <div key={index} className="text-center col-6 ">
            <img 
              src={imagen}
              alt=""
              height="75px"
              width="112px"
              className="imagenredonda"
            />
            <div>
            <button onClick={(e)=>removerImage(index,e)} className="my-3 btn btn-danger custom-btn">Remover</button>
            </div>
          </div>
                    ))}

        </div> */}

      <div className="row">
            {/* <div className="col-6 text-center mt-5">
              <a href="/services">
                <button className="btn btn-primary btn-lg ml-2 mb-4">Return</button>
              </a>
            </div> */}
            
            <div className="col-12 text-center">
              <div className="mb-5" >
              <div className="text-center">
              {/* {(prueba.length > muebles2) ? <button
                  className="btn btn-warning text-white custom-btn"
                  disabled={prueba!=''}
                >
                
                 Maximo {muebles2} imágenes
                </button>:
                <button
                  className="btn btn-warning text-white custom-btn "
                  disabled={prueba=='' || select==null}
                  onClick={UploadFiles}
                >
                
                  {texto}
                </button> } */}
                {( muebles2 ==1 ) && (<>
                  <button
                  className="btn btn-warning text-white custom-btn "
                  disabled={prueba =='' || select==null}
                  onClick={UploadFiles}
                >
                
                  {texto}
                </button> 
                </>)
                }
                {( muebles2 ==2 ) && (<>
                  <button
                  className="btn btn-warning text-white custom-btn "
                  disabled={prueba1 =='' || prueba2=='' || select==null}
                  onClick={UploadFiles}
                >
                
                  {texto}
                </button> 
                </>)
                }
                {( muebles2 ==3 ) && (<>
                  <button
                  className="btn btn-warning text-white custom-btn "
                  disabled={prueba3 =='' || prueba4==''|| prueba5=='' || select==null}
                  onClick={UploadFiles}
                >
                
                  {texto}
                </button> 
                </>)
                }
                {( muebles2 ==4 ) && (<>
                  <button
                  className="btn btn-warning text-white custom-btn "
                  disabled={prueba6 =='' || prueba7==''|| prueba8=='' || prueba9=='' || select==null}
                  onClick={UploadFiles}
                >
                
                  {texto}
                </button> 
                </>)
                }
                {( muebles2 !=4 && muebles2 !=3 &&muebles2 !=2 &&muebles2 !=1 ) && (<>
                  <button
                  className="btn btn-warning text-white custom-btn "
                  disabled={true}
                  onClick={UploadFiles}
                >
                
                  {texto}
                </button> 
                </>)
                }
              
              </div>

              </div>
            </div>
          </div>
          <Toaster
            position="bottom-center"
            reverseOrder={false}
          />
    </div>
    
  );
}

export default DragArea;