import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import axios from "axios";

axios.defaults.withCredentials = true;

ReactDOM.render(
  <React.StrictMode>
  <div className='division'>
    <App />
  </div>
    
  </React.StrictMode>,
  document.getElementById('root')
);

