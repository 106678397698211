import logo from "../logo-lavirginia.svg";
import logo2 from "./logo-fractal.png";
import logo3 from "./logo-loger.png";
import logo4 from "../cognitive.png"
import styled from '@emotion/styled';
import Select from 'react-select';
import axios from "axios";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "../App.css";
// import Dropdown from "./componentes/dropdown/dropdown.js";
import DragArea from "./dragArea/dragArea";
// import DragArea2 from "./componentes/dragArea/dragArea2";
import { useEffect,useState } from "react";
import DragArea3 from "./dragArea/dragArea3";

const FormularioV2 = () => { 
const [busqueda,setBusqueda] = useState('')
const [tabladFiltrado,setTablaFiltrado] = useState(["a","b","c"])
const [valores,setValores]=useState(["a","b","c"])
const [valoresaux,setValoresAux]=useState([1,2,3])
const [selectedOption, setSelectedOption] = useState(null);
const [valoresIP,setValoresIP] = useState(null)
const handleOptionChange = (selectedOption) => {
setSelectedOption(selectedOption);
};
const originalOptions = [
  {value: "0300009917", label: "CARREFOUR GBA\/036\/ITUZAINGO - Ariel Gerbacio"},
{value: "0300008037", label: "COTO (208) GBA\/MERLO\/J.D.PERON - Ariel Gerbacio"},
{value: "0300008036", label: "COTO (219) GBA\/MORENO\/AV VICTO - Ariel Gerbacio"},
{value: "0300003440", label: "VEA (159) GBA\/MERLO\/LIBERTAD - Ariel Gerbacio"},
{value: "0300009933", label: "DORINKA (1096)GBA\/CASEROS\/URQUIZA - Betiana Pastore"},
{value: "0300003598", label: "JUMBO GBA\/S.MARTIN\/S LORENZO - Betiana Pastore"},
{value: "0300004259", label: "COTO (57) GBA\/S.MARTIN\/S.MARTI - Betiana Pastore"},
{value: "0300003644", label: "COTO (164) GBA\/V BALLESTER\/J H - Betiana Pastore"},
{value: "0300004272", label: "COTO (111) GBA\/S.MARTIN\/BALBIN - Betiana Pastore"},
{value: "0300003581", label: "CARREFOUR GBA\/212\/MALAVER\/AMER - Betiana Pastore"},
{value: "0300006092", label: "MAYCAR\/GBA\/PILAR - Carlos Rampoldi"},
{value: "0300010024", label: "MAKRO GBA\/PILAR - Carlos Rampoldi"},
{value: "0300003600", label: "JUMBO GBA\/PILAR\/L MAGNOLIAS - Carlos Rampoldi"},
{value: "0300016925", label: "INC GBA\/PILAR\/RUTA 25 - Carlos Rampoldi"},
{value: "0300009932", label: "DORINKA (1087) GBA\/PILAR\/ACC NORTE - Carlos Rampoldi"},
{value: "0300005855", label: "DISCO\/GBA\/TORTUGUITAS - Carlos Rampoldi"},
{value: "0300003566", label: "INC GBA\/028\/PILAR\/RUTA 8 - Carlos Rampoldi"},
{value: "0300009917", label: "CARREFOUR GBA/036/ITUZAINGO - Claudio Carlomagno"},
{value: "0300008037", label: "COTO (208) GBA/MERLO/J.D.PERON - Claudio Carlomagno"},
{value: "0300003440", label: "VEA (159) GBA/MERLO/LIBERTAD - Claudio Carlomagno"},
{value: "0300008036", label: "COTO (219) GBA/MORENO/AV VICTO - Claudio Carlomagno"},
{value: "0300003712", label: "CARREFOUR GBA\/294\/ITUZAINGO\/BL - Claudio Carlomagno"},
{value: "0300009937", label: "COTO (190) GBA \/ CASTELAR \/STA ROSA - Claudio Carlomagno"},
{value: "0300006935", label: "COTO (215)GBA\/MORON\/ J S VALLE - Claudio Carlomagno"},
{value: "0300008212", label: "DORINKA CHANGO MAS (1116)\/MORON - Claudio Carlomagno"},
{value: "0300003601", label: "JUMBO GBA\/MORON\/VERGARA - Claudio Carlomagno"},
{value: "0300004169", label: "CARREFOUR GBA\/MORENO\/LIBERTADO - Claudio Landriel"},
{value: "0300003774", label: "DORINKA CHANGOMAS(1029)\/MORENO\/ROC - Claudio Landriel"},
{value: "0300009915", label: "INC GBA\/021\/MORENO - Claudio Landriel"},
{value: "0300003686", label: "CARREFOUR GBA\/042\/MERLO\/AV ARG - Claudio Landriel"},
{value: "0300007063", label: "DORINKA CHANGO MAS(1110)/CLAYPOLE - Cristian Bueno"},
{value: "0300003478", label: "MAXICONSUMO/GBA/BURZACO/MTE VE - Cristian Bueno"},
{value: "0300003456", label: "VITAL GBA/BURZACO/IRIGOYEN - Cristian Bueno"},
{value: "0300009904", label: "CARREFOUR MAXI\/GBA\/BURZACO - Cristian Bueno"},
{value: "0300005440", label: "CARREFOUR GBA\/56\/GLEW\/H YRIGOY - Cristian Bueno"},
{value: "0300004270", label: "COTO (105) GBA\/M.GRANDE\/B.AIRE - Cristian Bueno"},
{value: "0300003762", label: "DORINKA CHANGOMAS(1028)\/ALTE.BROWN - Cristian Bueno"},
{value: "0300003429", label: "INC GBA\/023\/ADROGUE\/H YR - Cristian Bueno"},
{value: "0300003599", label: "JUMBO GBA\/LAVALLOL\/FRIAS - Cristian Bueno"},
{value: "0300003482", label: "MAXICONSUMO GBA\/BOULOGNE\/YRIGO - Damian Blanco"},
{value: "0300003461", label: "MAXICONSUMO GBA\/L.HERMOSA\/MARQ - Damian Blanco"},
{value: "0300003448", label: "MAXICONSUMO GBA\/S.MIGUEL\/MITRE - Damian Blanco"},
{value: "0300004252", label: "VITAL GBA\/L.HERMOSA\/MARQUEZ - Damian Blanco"},
{value: "0300003530", label: "VITAL GBA\/MALVINAS ARGENTINAS - Damian Blanco"},
{value: "0300003488", label: "YAGUAR GBA\/J C PAZ\/R.197 - Damian Blanco"},
{value: "0300005777", label: "CARREFOUR MAXI\/GBA\/J.C.PAZ - Damian Blanco"},
{value: "0300004559", label: "COTO (19) CAP/CONSTITUCION/LIM - Diego Seguí"},
{value: "0300003797", label: "COTO (186) GBA\/LANUS\/H YRIGOYE - Diego Seguí"},
{value: "0300003551", label: "COTO (75) GBA\/LANUS\/RIVADAVIA - Diego Seguí"},
{value: "0300003769", label: "COTO 183\/BANFIELD\/AV.H.YRIGOYE - Diego Seguí"},
{value: "0300003555", label: "PLAZAVEA (692) GBA\/LANUS\/YRIGO - Diego Seguí"},
{value: "0200038188", label: "SUPERMERCADOS AMERICA DEL NORT - Diego Seguí"},
{value: "0300004197", label: "VEA (20) GBA\/LANUS\/YRIGOYEN - Diego Seguí"},
{value: "0300003453", label: "MAKRO GBA/L.ZAMORA/PERON - Elvio Santacruz"},
{value: "0300003557", label: "PLAZAVEA (698) GBA\/M.GRANDE\/B. - Elvio Santacruz"},
{value: "0300009914", label: "INC GBA\/016\/MTE GRANDE - Elvio Santacruz"},
{value: "0300009936", label: "DORINKACHANGOMAS\/GBA\/LOMAS\/CERRITO - Elvio Santacruz"},
{value: "0300004210", label: "DISCO (9) GBA\/L.ZAMORA\/MEEKS - Elvio Santacruz"},
{value: "0300003481", label: "DISCO (112) GBA\/BANFIELD\/VIEYT - Elvio Santacruz"},
{value: "0300003514", label: "COTO (107) GBA\/TEMPERLEY\/YRIGO - Elvio Santacruz"},
{value: "0300003697", label: "CARREFOUR GBA\/040\/TEMPERLEY\/AV - Elvio Santacruz"},
{value: "0300009903", label: "CARREFOUR GBA\/LAFERRERE\/J.M.DE R. - Estela de la Cruz"},
{value: "0300003895", label: "DORINKA (1043) LAFERRERE\/RUTA - Estela de la Cruz"},
{value: "0300008892", label: "MAXICONS\/GBA\/GONZALEZ CATAN - Estela de la Cruz"},
{value: "0300009935", label: "DORINKACHANGOMAS/GBA/CATAN/RUTA 3 - Estela de la Cruz"},
{value: "0300003480", label: "DORINKA (2998) GBA/S.JUSTO - Estela de la Cruz"},
{value: "0300003633", label: "COTO (171)GBA/TRISTAN SUAREZ/S - Ezequiel Vidal"},
{value: "0300003463", label: "MAKRO GBA\/S.JUSTO\/P.UNIDAS - Ezequiel Vidal"},
{value: "0300003805", label: "DISCO 170\/GBA\/CANNING - Ezequiel Vidal"},
{value: "0300003798", label: "COTO (189) GBA\/EZEIZA\/RUTA NAC - Ezequiel Vidal"},
{value: "0300003447", label: "MAXICONSUMO GBA\/S.JUSTO\/P.UNID - Ezequiel Vidal"},
{value: "0300003455", label: "VITAL GBA\/LAFERRERE\/R.3 - Ezequiel Vidal"},
{value: "0200045700", label: "VITAL GBA\/S.JUSTO\/BUFANO - Ezequiel Vidal"},
{value: "0300006232", label: "VITAL LPL/LA PLATA/AV.520 - Facundo Escudero"},
{value: "0300004990", label: "NINI LPL/520 - Facundo Escudero"},
{value: "0300009911", label: "INC GBA\/008\/LA PLATA\/CNO GRL - Facundo Escudero"},
{value: "0300004992", label: "DORINKA LPL\/LA PLATA\/3601 - Facundo Escudero"},
{value: "0300005000", label: "COTO (149) LPL\/LA PLATA\/43 - Facundo Escudero"},
{value: "0300009907", label: "CARREFOUR GBA\/144\/LA PLATA - Facundo Escudero"},
{value: "0200070230", label: "CARREFOUR GBA\/109\/LA PLATA\/CALLE 7 - Facundo Escudero"},
{value: "0200070225", label: "CARREFOUR GBA/020/AVELLANEDA/H YRIG - Facundo Séliz"},
{value: "0300004528", label: "COTO (124) CAP\/S.CRISTOBAL\/S.J - Facundo Séliz"},
{value: "0300004559", label: "COTO (19) CAP\/CONSTITUCION\/LIM - Facundo Séliz"},
{value: "0300007695", label: "COTO (220) CAP\/JOVELLANOS - Facundo Séliz"},
{value: "0300004553", label: "COTO (43) CAP\/BOEDO\/BOEDO - Facundo Séliz"},
{value: "0300003465", label: "DORINKA SRL (2999) GBA\/AVELLANEDA\/ - Facundo Séliz"},
{value: "0300004365", label: "PLAZAVEA (696) CAP\/S.CRISTOBAL - Facundo Séliz"},
{value: "0300006458", label: "INC\/MAXI\/LOMAS - Federico Paniagua"},
{value: "0300003453", label: "MAKRO GBA/L.ZAMORA/PERON - Federico Paniagua"},
{value: "0300003456", label: "VITAL GBA/BURZACO/IRIGOYEN - Federico Paniagua"},
{value: "0300003478", label: "MAXICONSUMO/GBA/BURZACO/MTE VE - Federico Paniagua"},
{value: "0300009892", label: "CARREFOUR GBA\/231\/L.DE ZAMORA - Federico Paniagua"},
{value: "0300009918", label: "CARREFOUR GBA\/052\/BANFIELD\/LAS H. - Federico Paniagua"},
{value: "0300004216", label: "DISCO (2) CAP/B.NORTE/TALCAHUA - Fernando Codemo"},
{value: "0300004402", label: "COTO (163) CAP/B NORTE/JUNIN - Fernando Codemo"},
{value: "0300004536", label: "COTO (80) CAP/RECOLETA/FRENCH - Fernando Codemo"},
{value: "0300004806", label: "DISCO (35) CAP/B.NORTE/URIBURU - Fernando Codemo"},
{value: "0300004819", label: "INC CAP/006/SALGUERO/SAL - Fernando Codemo"},
{value: "0300006638", label: "COTO 203/CAP/BOTANICO - Fernando Codemo"},
{value: "0300004359", label: "DISCO (137) CAP/B.NORTE/MONTEV - Fernando Codemo"},
{value: "0300009933", label: "DORINKA (1096)GBA/CASEROS/URQUIZA - Giselle Gimenez"},
{value: "0300004259", label: "COTO (57) GBA/S.MARTIN/S.MARTI - Giselle Gimenez"},
{value: "0300004272", label: "COTO (111) GBA/S.MARTIN/BALBIN - Giselle Gimenez"},
{value: "0300003598", label: "JUMBO GBA/S.MARTIN/S LORENZO - Giselle Gimenez"},
{value: "0300003581", label: "CARREFOUR GBA/212/MALAVER/AMER - Giselle Gimenez"},
{value: "0300003644", label: "COTO (164) GBA/V BALLESTER/J H - Giselle Gimenez"},
{value: "0300009884", label: "CARREFOUR GBA\/201\/CARAPACHAY\/INDEP. - Giselle Gimenez"},
{value: "0300004255", label: "COTO (38) GBA\/FLORIDA\/S.MARTIN - Giselle Gimenez"},
{value: "0300003706", label: "COTO 188\/GBA\/MUNRO\/B MITRE - Giselle Gimenez"},
{value: "0200078726", label: "JUMBO GBA\/MARTINEZ\/PARANA - Giselle Gimenez"},
{value: "0300004532", label: "COTO (90) CAP\/V.DEVOTO\/SEGUROL - Ismael Gomez"},
{value: "0300004258", label: "COTO (51) GBA\/R.MEJIA\/RIVADAVI - Ismael Gomez"},
{value: "0300004247", label: "COTO (129) GBA\/CIUDADELA\/ACC.N - Ismael Gomez"},
{value: "0300004793", label: "COTO (125) CAP\/M CASTRO\/L.VEGA - Ismael Gomez"},
{value: "0300004567", label: "CARREFOUR CAP\/030\/MATADEROS\/GR - Ismael Gomez"},
{value: "0300004992", label: "DORINKA LPL/LA PLATA/3601 - Ivan Pelozo"},
{value: "0300009911", label: "INC GBA/008/LA PLATA/CNO GRL - Ivan Pelozo"},
{value: "0300005000", label: "COTO (149) LPL/LA PLATA/43 - Ivan Pelozo"},
{value: "0300004990", label: "NINI LPL\/520 - Ivan Pelozo"},
{value: "0300009928", label: "VEA (SUC 636) LA PLATA\/ CALLE 13 - Ivan Pelozo"},
{value: "0300006232", label: "VITAL LPL\/LA PLATA\/AV.520 - Ivan Pelozo"},
{value: "0300003618", label: "DORINKA (1017)GBA/TABLADA - Jonathan Ortiz"},
{value: "0300004416", label: "COTO (63) CAP/POMPEYA/ROCA - Jonathan Ortiz"},
{value: "0300011320", label: "MAXICONSUMO S.A. \/TAPIALES - Jonathan Ortiz"},
{value: "0300009913", label: "INC GBA\/012\/SAN JUSTO \/DON - Jonathan Ortiz"},
{value: "0200049975", label: "DIARCO MERCADO CENTRAL - Jonathan Ortiz"},
{value: "0300004268", label: "COTO (94) GBA\/TAPIALES\/B.SURME - Jonathan Ortiz"},
{value: "0300004529", label: "COTO (110) CAP\/V.LUGANO\/S.FRON - Jonathan Ortiz"},
{value: "0300008036", label: "COTO (219) GBA/MORENO/AV VICTO - Jorge Galban"},
{value: "0300008037", label: "COTO (208) GBA/MERLO/J.D.PERON - Jorge Galban"},
{value: "0300004498", label: "COTO (180)CAP\/FLORES\/S PEDRITO - Jorge Galban"},
{value: "0300004548", label: "COTO (48) CAP\/FLORES\/DIRECTORI - Jorge Galban"},
{value: "0300004544", label: "COTO (55) CAP\/FLORESTA\/AVELLAN - Jorge Galban"},
{value: "0300004787", label: "COTO (83) CAP\/V.LURO\/RIVADAVIA - Jorge Galban"},
{value: "0300004533", label: "COTO (85) CAP\/MATADEROS\/E.PERO - Jorge Galban"},
{value: "0300004362", label: "DISCO (701) CAP\/CABALLITO\/DIRE - Jorge Galban"},
{value: "0300009908", label: "INC CAP\/005\/VELEZ SAR\/ALVAREZ - Jorge Galban"},
{value: "0300004302", label: "INC\/02\/VICENTE LOPE - José Alderete"},
{value: "0300004269", label: "COTO (92) GBA\/V.LOPEZ\/MAIPU - José Alderete"},
{value: "0300003579", label: "CARREFOUR\/216\/VICENTE LOPEZ-MAIPU - José Alderete"},
{value: "0300009890", label: "CARREFOUR GBA\/226\/OLIVOS\/AV.STA FE - José Alderete"},
{value: "0300003583", label: "CARREFOUR GBA\/204\/OLIVOS\/GDOR - José Alderete"},
{value: "0300004560", label: "COTO (2) CAP/PATERNAL/S.MARTIN - Julian Cantilo"},
{value: "0300008992", label: "CARREFOUR 198/NUÑEZ/AV.CABILDO - Julian Cantilo"},
{value: "0300009866", label: "CARREFOUR 120/BELGRANO/CABILDO-MON - Julian Cantilo"},
{value: "0300015867", label: "FRESH MARKET DOT - Julian Cantilo"},
{value: "0200049963", label: "MASIVOS CAP\/MATADEROS\/RODO - Julian Cantilo"},
{value: "0200046805", label: "MAXICONSUMO\/CAP\/V DEL PARQUE\/B - Julian Cantilo"},
{value: "0300004562", label: "VITAL CAP\/CHACARITA\/TRONADOR - Julian Cantilo"},
{value: "0300004566", label: "VITAL CAP\/ONCE\/GALLO - Julian Cantilo"},
{value: "0300004589", label: "YAGUAR GBA\/CAPITAL\/AUTOPISTA - Julian Cantilo"},
{value: "0300004489", label: "COTO (22) CAP/BELGRANO/ELCANO - Leandro Rojas"},
{value: "0300004494", label: "COTO (170) CAP/NUÑEZ/LIBERTADOR - Leandro Rojas"},
{value: "0300004541", label: "COTO (59) CAP/V.URQUIZA/MONROE - Leandro Rojas"},
{value: "0300004552", label: "COTO (44) CAP/COUGHLAN/MONROE - Leandro Rojas"},
{value: "0300009878", label: "CARREFOUR CAP/271/BELGRANO/MONROE - Leandro Rojas"},
{value: "0300004579", label: "DISCO 977\/CAP\/BELGRANO\/CUBA - Leandro Rojas"},
{value: "0300004549", label: "COTO (47) CAP\/BELGRANO\/AMENABA - Leandro Rojas"},
{value: "0300004551", label: "COTO (45) CAP\/BELGRANO\/CABILDO - Leandro Rojas"},
{value: "0300003894", label: "DORINKA CHANGOMAS(1050)LANUS\/D.ALV - Lucas Lukaczuk"},
{value: "0300004343", label: "MAKRO GBA\/AVELLANEDA\/PIENOVI - Lucas Lukaczuk"},
{value: "0300003450", label: "MAKRO GBA\/QUILMES\/CALCHAQUI - Lucas Lukaczuk"},
{value: "0300003570", label: "MAXICONSUMO\/GBA\/V DOMINICO\/C U - Lucas Lukaczuk"},
{value: "0300004309", label: "VITAL GBA\/AVELLANEDA\/PIENOVI - Lucas Lukaczuk"},
{value: "0300004245", label: "VITAL GBA\/QUILMES\/CALCHAQUI - Lucas Lukaczuk"},
{value: "0300003430", label: "DISCO (23) CAP/PALERMO/BULNES - Macario Torrico"},
{value: "0300004209", label: "VEA (10) CAP/ONCE/LARREA - Macario Torrico"},
{value: "0300004523", label: "JUMBO CAP/PALERMO/CERVIÑO - Macario Torrico"},
{value: "0300004543", label: "COTO (56) CAP/PALERMO/HONDURAS - Macario Torrico"},
{value: "0300004804", label: "DISCO (39) CAP/PALERMO/PEÑA - Macario Torrico"},
{value: "0300009862", label: "CARREFOUR CAP/148/B.NORTE/SANTA FE - Macario Torrico"},
{value: "0300009874", label: "CARREFOUR CAP/215/PALERMO/R.E.ORTIZ - Macario Torrico"},
{value: "0300004402", label: "COTO (163) CAP\/B NORTE\/JUNIN - Macario Torrico"},
{value: "0300017212", label: "CHANGO MAS GBA/CAMPANA - Manuel Urueña"},
{value: "0300009906", label: "CARREFOUR \/232\/ROS\/CAMPANA - Manuel Urueña"},
{value: "0300003640", label: "CARREFOUR GBA\/183\/ING.MASCHWIT - Manuel Urueña"},
{value: "0300003567", label: "COTO (117) GBA\/CAMPANA\/J M EST - Manuel Urueña"},
{value: "0300003574", label: "COTO (154) GBA\/ESCOBAR\/T CRUZ - Manuel Urueña"},
{value: "0300003603", label: "JUMBO GBA\/ESCOBAR\/PANAMERICANA - Manuel Urueña"},
{value: "0300001695", label: "MAXICONSUMO ROS\/CAMPANA\/AV.RIV - Manuel Urueña"},
{value: "0300006047", label: "YAGUAR GBA\/INGENIERO MASCHWITZ - Manuel Urueña"},
{value: "0300001889", label: "YAGUAR ROS CAMP-R6YPANAM - Manuel Urueña"},
{value: "0300010609", label: "DIA CAP/PAYSANDU - Mariela Glauche"},
{value: "0300004411", label: "VEA (119) CAP\/V.CRESPO\/ARAOZ - Mariela Glauche"},
{value: "0300004524", label: "JUMBO CAP\/ALMAGRO\/YATAY - Mariela Glauche"},
{value: "0300004681", label: "JUMBO 5263(CAP)JB JUSTO 4701 - Mariela Glauche"},
{value: "0300004555", label: "COTO (41) CAP\/ALMAGRO\/D.VELEZ - Mariela Glauche"},
{value: "0300004531", label: "COTO (103) CAP\/CABALLITO\/GAONA - Mariela Glauche"},
{value: "0300004581", label: "COTO #168CAP\/V CRESPO\/JB JUSTO - Mariela Glauche"},
{value: "0300004492", label: "CARREFOUR GBA\/219\/CABALLITO\/AV - Mariela Glauche"},
{value: "0300003584", label: "CARREFOUR GBA\/211\/MARTINEZ\/SAE - Mariela Moyano"},
{value: "0300009888", label: "CARREFOUR GBA\/203\/CHILAVERT\/LACROZE - Mariela Moyano"},
{value: "0300009900", label: "CARREFOUR GBA\/254\/SAN ISIDRO\/CARMAN - Mariela Moyano"},
{value: "0300009902", label: "CARREFOUR GBA\/278\/BOULOGNE - Mariela Moyano"},
{value: "0300009901", label: "CARREFOUR\/272\/SAN FERNANDO - Mariela Moyano"},
{value: "0300004265", label: "COTO (67) GBA\/ACASUSSO\/ALVAREL - Mariela Moyano"},
{value: "0300004264", label: "COTO (68) GBA\/L.S.ISIDRO\/NEWBE - Mariela Moyano"},
{value: "0300004263", label: "COTO (69) GBA\/P.CHICA\/LIBERTAD - Mariela Moyano"},
{value: "0300004217", label: "DISCO (1) GBA\/S.ISIDRO\/CENTENA - Mariela Moyano"},
{value: "0300003774", label: "DORINKA CHANGOMAS(1029)/MORENO/ROC - Mario Lopez"},
{value: "0300003686", label: "CARREFOUR GBA/042/MERLO/AV ARG - Mario Lopez"},
{value: "0300004169", label: "CARREFOUR GBA/MORENO/LIBERTADO - Mario Lopez"},
{value: "0300009915", label: "INC GBA/021/MORENO - Mario Lopez"},
{value: "0300003738", label: "DIARCO/GBA/MORON - Mario Lopez"},
{value: "0300003433", label: "MAKRO GBA\/HAEDO\/GUEMES - Mario Lopez"},
{value: "0300004295", label: "MAKRO GBA\/ITUZAINGO\/PERON - Mario Lopez"},
{value: "0300003458", label: "MAXICONSUMO GBA\/MERLO\/BALBIN - Mario Lopez"},
{value: "0300003875", label: "MAXICONSUMO\/GBA\/MORENO - Mario Lopez"},
{value: "0300013383", label: "RICARDO NINI\/PASO DEL REY - Mario Lopez"},
{value: "0300003424", label: "VITAL GBA\/MORENO\/GAONA - Mario Lopez"},
{value: "0300004080", label: "YAGUAR GBA\/MORENO - Mario Lopez"},
{value: "0300010608", label: "DIA CAP/AV.SAN JUAN - Marta Dandl"},
{value: "0300004204", label: "DISCO (18) CAP/RECOLETA/QUINTA - Marta Dandl"},
{value: "0300004405", label: "DISCO (46) CAP/RETIRO/ESMERALD - Marta Dandl"},
{value: "0300004496", label: "COTO (160) CAP/RETIRO/ANTARTID - Marta Dandl"},
{value: "0300004542", label: "COTO (58) CAP/B.NORTE/VIAMONTE - Marta Dandl"},
{value: "0300009864", label: "CARREFOUR CAP/118/B.NORTE/VTE.LOPEZ - Marta Dandl"},
{value: "0300009868", label: "CARREFOUR CAP/148/CENTRO/RIVADAVIA - Marta Dandl"},
{value: "0300014028", label: "INC S.A\/MALVINAS ARGENTINAS - Martín Cortez"},
{value: "0300009926", label: "DISCO GBA\/TIGRE\/NORDELTA - Martín Cortez"},
{value: "0300016355", label: "COTO GBA\/182\/NORDELTA - Martín Cortez"},
{value: "0300003986", label: "COTO (197) GBA\/GARIN - Martín Cortez"},
{value: "0300003803", label: "COTO (184) GBA\/MALVINAS ARGENT - Martín Cortez"},
{value: "0300004266", label: "COTO (101) GBA\/AVELLANEDA\/ITAL - Martín Meza"},
{value: "0300009891", label: "CARREFOUR GBA\/227\/BERNAL\/AB.D.ROCHA - Martín Meza"},
{value: "0300003587", label: "CARREFOUR GBA\/101\/AVELLANEDA\/B - Martín Meza"},
{value: "0200070225", label: "CARREFOUR GBA\/020\/AVELLANEDA\/H YRIG - Martín Meza"},
{value: "0300003468", label: "COTO (64) GBA\/AVELLANEDA\/MITRE - Martín Meza"},
{value: "0300003617", label: "DORINKA (1016)GBA\/AVELLANEDA - Martín Meza"},
{value: "0300009891", label: "CARREFOUR GBA/227/BERNAL/AB.D.ROCHA - Melgarejo Heber"},
{value: "0300003468", label: "COTO (64) GBA/AVELLANEDA/MITRE - Melgarejo Heber"},
{value: "0300004266", label: "COTO (101) GBA/AVELLANEDA/ITAL - Melgarejo Heber"},
{value: "0300003617", label: "DORINKA (1016)GBA/AVELLANEDA - Melgarejo Heber"},
{value: "0300007063", label: "DORINKA CHANGO MAS(1110)\/CLAYPOLE - Melgarejo Heber"},
{value: "0300003476", label: "INC GBA\/007\/QUILMES\/AV L - Melgarejo Heber"},
{value: "0300004260", label: "COTO (65) GBA\/QUILMES\/YRIGOYEN - Melgarejo Heber"},
{value: "0300004273", label: "COTO (130) GBA\/BERNAL\/ROCHA - Melgarejo Heber"},
{value: "0300007759", label: "CARREFOUR MAXI 704\/EZPELETA - Melgarejo Heber"},
{value: "0300003937", label: "COTO (192) GBA\/SAN FERNANDO - Miguel Castillo"},
{value: "0300003484", label: "DISCO (235)GBA\/L.HORQUETA\/B.EN - Miguel Castillo"},
{value: "0300003696", label: "DORINKA (1004) GBA\/SAN FERNAN - Miguel Castillo"},
{value: "0300009910", label: "INC GBA\/001\/SAN ISIDRO\/B IRIG - Miguel Castillo"},
{value: "0300009916", label: "INC GBA\/025\/SAN FERNANDO - Miguel Castillo"},
{value: "0300010608", label: "DIA CAP/AV.SAN JUAN - Miguel Placencia"},
{value: "0300004537", label: "COTO (78) CAP\/BALBANERA\/MEXICO - Miguel Placencia"},
{value: "0300004417", label: "COTO (61) CAP\/ONCE\/ALSINA - Miguel Placencia"},
{value: "0300004550", label: "COTO (46) CAP\/ONCE\/RIVADAVIA - Miguel Placencia"},
{value: "0300004497", label: "COTO (159) CAP\/CONGRESO\/MITRE - Miguel Placencia"},
{value: "0300009846", label: "CARREFOUR CAP\/151\/ONCE\/RIVADAVIA - Miguel Placencia"},
{value: "0300010101", label: "DIA CAP/ARTIGAS - Natalia Albornoz"},
{value: "0300004361", label: "PLAZAVEA (700) CAP/CHACARITA/C - Natalia Albornoz"},
{value: "0300004525", label: "CARREFOUR CAP/206/BARRIO NORTE - Natalia Albornoz"},
{value: "0300004539", label: "COTO (66) CAP/BELGRANO/MAURE - Natalia Albornoz"},
{value: "0300004549", label: "COTO (47) CAP/BELGRANO/AMENABA - Natalia Albornoz"},
{value: "0300004551", label: "COTO (45) CAP/BELGRANO/CABILDO - Natalia Albornoz"},
{value: "0300004554", label: "COTO (42) CAP/B.NORTE/PARAGUAY - Natalia Albornoz"},
{value: "0300004558", label: "COTO (24) CAP/PALERMO/S.ORTIZ - Natalia Albornoz"},
{value: "0300004568", label: "COTO (158) GBA/PALERMO/CHARCAS - Natalia Albornoz"},
{value: "0300004571", label: "COTO (176)GBA/BELGRANO/T.GARCI - Natalia Albornoz"},
{value: "0300004579", label: "DISCO 977/CAP/BELGRANO/CUBA - Natalia Albornoz"},
{value: "0300009871", label: "CARREFOUR CAP/150/PALERMO/STA FE - Natalia Albornoz"},
{value: "0300003580", label: "CARREFOUR\/217\/BELLA VISTA - Nicolas Michalkow"},
{value: "0300003552", label: "COTO (123) GBA\/B.VISTA\/OHIGGIN - Nicolas Michalkow"},
{value: "0300004267", label: "COTO (131) GBA\/J.C.PAZ\/YRIGOYE - Nicolas Michalkow"},
{value: "0300003434", label: "DISCO (163) GBA\/B.VISTA\/S.MORO - Nicolas Michalkow"},
{value: "0300003705", label: "DORINKA CHAN MAS 1013\/TIGRE\/PANAM - Nicolas Michalkow"},
{value: "0300003777", label: "DORINKA CHANGOMAS(1032)\/GRAND BOUR - Nicolas Michalkow"},
{value: "0200045703", label: "MAKRO GBA\/OLIVOS - Nicolas Nadin"},
{value: "0300003457", label: "MAXICONSUMO GBA\/PACHECO\/R.197 - Nicolas Nadin"},
{value: "0300003556", label: "PLAZAVEA (697) GBA\/PACHECO\/R.1 - Nicolas Nadin"},
{value: "0300003569", label: "VITAL GBA\/PACHECO\/R.197 - Nicolas Nadin"},
{value: "0200038164", label: "YAGUAR GBA\/TIGRE\/ACC NORTE - Nicolas Nadin"},
{value: "0300009893", label: "CARREFOUR GBA\/239\/PACHECO\/SUR MER - Nicolas Nadin"},
{value: "0300009889", label: "CARREFOUR GBA\/221\/TIGRE\/BROWN - Nicolas Nadin"},
{value: "0300004260", label: "COTO (65) GBA/QUILMES/YRIGOYEN - Nora Barrionuevo" },
{value: "0300004273", label: "COTO (130) GBA/BERNAL/ROCHA - Nora Barrionuevo" },
{value: "0300005018", label: "CARREFOUR GBA\/049\/FLORENCIO VARELA\/ - Nora Barrionuevo" },
{value: "0300004271", label: "COTO (104) GBA\/QUILMES\/12OCTUB - Nora Barrionuevo"},
{value: "0300011403", label: "COTO (235) GBA\/QUILMES\/H.PRIMO - Nora Barrionuevo"},
{value: "0300003619", label: "DORINKA (1018)GBA\/QUILMES - Nora Barrionuevo"},
{value: "0300003602", label: "JUMBO GBA\/QUILMES\/CALCHAQUI - Nora Barrionuevo"},
{value: "0200038777", label: "SUPER MERCADO TIBURON SRL EN FORM - Nora Barrionuevo"},
{value: "0300004522", label: "JUMBO CAP\/ESCALADA\/CRUZ - Omar Castro"},
{value: "0300009909", label: "INC CAP\/026\/WARNES\/AV WARNES - Omar Castro"},
{value: "0300004540", label: "COTO (60) CAP\/FLORES\/ALBERDI - Omar Castro"},
{value: "0300004547", label: "COTO (52) CAP\/V.DEVOTO\/SANABRI - Omar Castro"},
{value: "0300004575", label: "COTO (167) CAP\/PATERNAL\/NAZCA - Omar Castro"},
{value: "0300004488", label: "COTO (153) CAP\/DEVOTO\/A.JONTE - Omar Castro"},
{value: "0300004423", label: "COTO (151) CAP\/P.CHACABUCO\/ASA - Omar Castro"},
{value: "0300009866", label: "CARREFOUR 120/BELGRANO/CABILDO-MON - Otero Alderete"},
{value: "0300004524", label: "JUMBO CAP/ALMAGRO/YATAY - Otero Alderete"},
{value: "0300004405", label: "DISCO (46) CAP/RETIRO/ESMERALD - Otero Alderete"},
{value: "0300004589", label: "YAGUAR GBA/CAPITAL/AUTOPISTA - Otero Alderete"},
{value: "0300004681", label: "JUMBO 5263(CAP)JB JUSTO 4701 - Otero Alderete"},
{value: "0300004531", label: "COTO (103) CAP/CABALLITO/GAONA - Otero Alderete"},
{value: "0300015867", label: "FRESH MARKET DOT - Otero Alderete"},
{value: "0300004560", label: "COTO (2) CAP/PATERNAL/S.MARTIN - Otero Alderete"},
{value: "0300010101", label: "DIA CAP/ARTIGAS - Otero Alderete"},
{value: "0300008992", label: "CARREFOUR 198/NUÑEZ/AV.CABILDO - Otero Alderete"},
{value: "0300004492", label: "CARREFOUR GBA/219/CABALLITO/AV - Otero Alderete"},
{value: "0300004401", label: "COTO (91) CAP/ABASTO/AGUERO - Patricia Gioffre"},
{value: "0300004418", label: "DISCO (247) CAP/CABALLITO/MORE - Patricia Gioffre"},
{value: "0300004479", label: "JUMBO/CAP/AV.ACOYTE - Patricia Gioffre"},
{value: "0300004527", label: "COTO (136) CAP/CABALLITO/GOYEN - Patricia Gioffre"},
{value: "0300004534", label: "COTO (84) CAP/ALMAGRO/C.BARROS - Patricia Gioffre"},
{value: "0300004535", label: "COTO (82) CAP/V.CRESPO/MALABIA - Patricia Gioffre"},
{value: "0300004818", label: "DISCO (30) CAP/CABALLITO/RIVAD - Patricia Gioffre"},
{value: "0200038541", label: "DORINKA (2997) CAP/CONSTITUYEN - Sebastian Sanchez"},
{value: "0300003466", label: "INC GBA/015/SAN MARTIN/A - Sebastian Sanchez"},
{value: "0300004363", label: "JUMBO 5259 CAP/V.HORTUSA/T - Sebastian Sanchez"},
{value: "0300004813", label: "COTO (181)CAP/SAAVEDRA/R BALBI - Sebastian Sanchez"},
{value: "0300009875", label: "CARREFOUR CAP/220/V.URQUIZA/ROOS - Sebastian Sanchez"},
{value: "0300009877", label: "CARREFOUR CAP/258/V.PUEYRREDON - Sebastian Sanchez"},
{value: "0300003586", label: "CARREFOUR GBA\/234\/DON TORCUATO - Sergio Ponce"},
{value: "0300003585", label: "CARREFOUR GBA\/218\/HURLINGHAM\/A - Sergio Ponce"},
{value: "0300003582", label: "CARREFOUR GBA\/205\/PALOMAR\/LOS - Sergio Ponce"},
{value: "0300003691", label: "CARREFOUR GBA\/047\/MARTIN CORON - Sergio Ponce"},
{value: "0300003873", label: "DORINKA CHANGOMAS(1035)MI CHANGO\/3F - Sergio Ponce"},
{value: "0300003431", label: "INC \/11\/MALVINAS - Sergio Ponce"},
{value: "0300009912", label: "INC GBA\/010\/MORON - Sergio Ponce"},
];
const options = originalOptions.map((option) => ({
  value: option.value,
  label: option.label.split(' - ')[0], // Mostrar solo la parte antes del guión
  repositorName: option.label.split(' - ')[1], // Guardar el nombre del repositor
}));
const filtrarArray=(array) =>{
var ayuda2 = [];
var items2 = array
  for (let i = 0; i<items2.length; i++){
    ayuda2.push(i)
  }
  setValores(items2)
  setValoresAux(ayuda2)
  
//   setCurrentItems(ayuda2.slice(itemOffset, itemOffset + itemsPerPage));
//   setPageCount(Math.ceil(items2.length / itemsPerPage));
}
const handleChange = (e) => {
setBusqueda(e.target.value)
filtrar(e.target.value)
}

const filtrar =(terminoBusqueda)=>{
var resultadosBusqueda=tabladFiltrado.filter((elemento) => {
  if(
    (elemento.image != null &&  elemento.image.includes(terminoBusqueda.toLowerCase()))
  || 
   (elemento.job_id != null && elemento.job_id.includes(terminoBusqueda.toLowerCase() ))
   || 
 (elemento.created_at != null && elemento.created_at.toString().includes(terminoBusqueda.toLowerCase() ))
  ){
    return elemento;
  }
});


filtrarArray(resultadosBusqueda)
}
const getIp = async()=>{

  try {
      // const response = await axios.get('https://ipgeolocation.abstractapi.com/v1/?api_key=bfa2898e16254f0baa4d590f25a4268f');
      // console.log(response);
      const response = await fetch('https://ipgeolocation.abstractapi.com/v1/?api_key=bfa2898e16254f0baa4d590f25a4268f');
const data = await response.json();

setValoresIP({
  ip_user : data.ip_address,
user_country: data.city +", " + data.country,
conection_type: data.connection.connection_type,
latitude:data.latitude,
longitude:data.longitude,
current_time: data.timezone.current_time,
})
    } catch (error) {
      console.error(error);
    }
} 
useEffect(() => {
getIp();
}, []);

return (
<div className="App container fondo">
<br></br>
        <div className="row ">
        
            <div className="col-4 text-center">

            </div>
            <div className="col-4 text-center">

            </div>
            {/* <div className="col-4 text-center">
                <a href="/">Formulario actual</a>
            </div> */}
        </div>
{/* <Formulario></Formulario> */}
{/* <DragArea2 handleImageUpload={handleImageUpload} /> */}
  <header className="text-center ">
  <div className="container">
    <img src={logo} className="App-logo img-fluid mt-5" alt="logo" />
  </div>
    
    {/* <Dropdown dropdownTitle="primer iterado" items={items} /> */}

    <br></br>
    <br></br>
    <div className="container">
    <div className="row mt-5 ">
      <div className="col-7 text-center">
      <Select options={options} isSearchable onChange={handleOptionChange} />
      </div>
      <div className="col-5 text-left-important my-auto">
      {/* <Label>Punto de venta</Label> */}
      <h5 className="h5 text-white letra-standar">Punto de Venta</h5>
      </div>
    </div>
    <div className="row  mt-2">
      <div className="col-7">
      {selectedOption && (
    
      
      <div style={{ marginTop: '5px', background: '#fff', borderRadius: '4px', padding: '6px', border: '1px solid #ced4da', display: 'flex', alignItems: 'center' }}>
        
        <span style={{ color: '#777' }} className="letra-standar"> {selectedOption.repositorName}</span>
      </div>
  )}
  {!selectedOption && (
    
      
    <div style={{ marginTop: '0px', background: '#fff', borderRadius: '4px', padding: '6px', border: '1px solid #ced4da', display: 'flex', alignItems: 'center' }}>
      
      <span style={{ color: '#777' }} className="letra-standar">No seleccionado</span>
    </div>
)}
      </div>
      <div className="col-5 text-left-important my-auto">
      <h5 className="h5 text-white letra-standar">Repositor </h5>
      </div>
    </div>
    <div className="row mt-2">
      <div className="col-7">
      <div style={{ marginTop: '0px', background: '#fff', borderRadius: '4px', padding: '6px', border: '1px solid #ced4da', display: 'flex', alignItems: 'center' }}>
      
      <span style={{ color: '#777' }} className="letra-standar">Especias</span>
    </div>
      </div>
      <div className="col-5 text-left-important my-auto">
      <h5 className="h5 text-white letra-standar">Categoría</h5>
      </div>
    </div>
    </div>
    
    
    <div className="text-center  mt-5">
    
      <DragArea3 select={selectedOption && selectedOption.repositorName} puntoVenta={selectedOption && selectedOption.value} datosIp={valoresIP}></DragArea3>
    </div>

    <div className="row mt-3">
    
      <div className="col-4 text-center my-auto">
        <img src={logo3} className="text-white" width={120} alt="logo" />
      </div>
      <div className="col-4 text-center my-auto">
        <img src={logo4} width={120} alt="logo" />
      </div>
      <div className="col-4 text-center my-auto">
        <img src={logo2} className="mt-2" width={120} alt="logo" />
      </div>
    </div>
    <br></br>
    <br></br>
  </header>
</div>
);
}


export default FormularioV2;
